import { request } from '../utils/httpClient';

export const getOfficerElectionInstructions = (data) => {
    return request({
      url: `/membership/officers-elections/instructions/${data.id}`,
      method: 'GET',
    });
  };

export const getOfficerElectionOfficiers = (data) => {
    return request({
      url: `/membership/officers-elections/officers/${data.id}`,
      method: 'GET',
    });
  };

export const getOfficerElectionSchedule = (data) => {
    return request({
      url: `/membership/officers-elections/schedules/${data.id}`,
      method: 'GET',
    });
  };
  
export const addOfficierElectionSchedule = (data) => {
    let formId = data.id;
    delete data.id;
    return request({
        url: `/membership/officers-elections/schedules/${formId}`,
        method: 'POST',
        data: data,
    });
};

export const updateOfficierElectionSchedule = (data) => {
    let formId = data.id;
    delete data.id;
    return request({
      url: `/membership/officers-elections/schedules/${formId}`,
      method: 'PATCH',
      data: data,
    });
  };

  export const deleteOfficerElectionSchedule = (data) => {
    return request({
      url: `/membership/officers-elections/schedules/${data.id}/${data.schedule_id}`,
      method: 'DELETE',
    });
  };

  
  export const addOfficierElectionOfficer = (data) => {
    let formId = data.id;
    delete data.id;
    return request({
        url: `/membership/officers-elections/officers/${formId}`,
        method: 'POST',
        data: data,
    });
};

export const updateOfficierElectionOfficer = (data) => {
    let formId = data.id;
    delete data.id;
    return request({
      url: `/membership/officers-elections/officers/${formId}`,
      method: 'PATCH',
      data: data,
    });
  };

  export const getOfficerElectionSummary = (data) => {
    return request({
      url: `/membership/officers-elections/summary/${data.id}`,
      method: 'GET',
    });
  }

export const completeOfficerElection = (data) => {
  return request({
    url: `/membership/officers-elections/complete/${data.id}`,
    method: 'POST',
  });
}

export const updateElectionOfficerAssignee = (data) => {
  let formId = data.id;
  delete data.id;

  return request({
    url: `/membership/officers-elections/assignments/${formId}`,
    method: 'PATCH',
    data: data,
  });
}