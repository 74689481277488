import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Step, Stepper, StepLabel, Button } from '@mui/material';
import ElectionInstructions from './ElectionInstructions';
import ChapterMeetingInfo from './ChapterMeetingInfo';
import ElectionSummary from './ElectionSummary';
import OfficersVerification from './OffVerification';


const Spacer = () => {
  return <Grid item xs={1} sm={2} md={3} />;
};


const OfficierElection = () => {
  let { id } = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const [step1Complete, setStep1Complete] = useState(false);
  const [step2Complete, setStep2Complete] = useState(false);
  const [step3Complete, setStep3Complete] = useState(false);

  let steps = [
    {
        name: 'Instructions',
        state: step1Complete,
        step: 1,
    },
    {
        name: 'Meeting Info',
        state: step2Complete,
        step: 2,
    },
    {
        name: 'Officers Verification',
        state: step3Complete,
        step: 3,
    },
    {
        name: 'Summary / Confirmation',
        state: false,
        step: 4,
    },
  ]

  const resetSteps = () => {
    setStep1Complete(false);
    setStep2Complete(false);
    setStep3Complete(false);
    setActiveStep(0);
  }

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setStep1Complete(true);
        break;
      case 1:
        setStep2Complete(true);
        break;
      case 2:
        setStep3Complete(true);
        break;
      default:
        break;
    }
    if (activeStep >= steps.length - 1) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    switch (activeStep) {
      case 1:
        setStep1Complete(false);
        break;
      case 2:
        setStep2Complete(false);
        break;
      case 3:
        setStep3Complete(false);
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const activeScreen = () => {
      switch (activeStep) {
        case 0:
          return (
            <ElectionInstructions id={id} handleNext={handleNext} formStep={activeStep} />
          );
        case 1:
          return (
            <ChapterMeetingInfo id={id} handleNext={handleNext} handleBack={handleBack} />
          );
        case 2:
          return (
            <OfficersVerification id={id} handleNext={handleNext} handleBack={handleBack} />
          );
        case 3:
          return (
            <ElectionSummary id={id} handleNext={handleNext} handleBack={handleBack} formStep={activeStep} reset={resetSteps} />
          );
        default:
          return (
            <Grid>
                <Typography variant="h1">Step 1</Typography>
                <Button onClick={handleNext}>Next</Button>
            </Grid>
          );
      }
    };

  return (
    <Grid container sx={{ minHeight: '85vh' }} paddingTop={5}>
        <Grid item container xs={12}>
            <Spacer />
            <Grid item xs={10} sm={8} md={6} sx={{ backgroundColor: '#ffffff' }}>
            <Stepper activeStep={activeStep} sx={{ p: 2 }}>
                {steps.map((step, index) => {
                return (
                    <Step key={step.name} completed={step.state}>
                    <StepLabel>{step.name}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>
            <Grid item paddingRight paddingLeft>
                {activeScreen()}
            </Grid>
            </Grid>
            <Spacer />
        </Grid>
    </Grid>
  );
};

export default OfficierElection;