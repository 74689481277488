import React, {useEffect, useState} from 'react';
import { Grid, Typography, Button, MenuItem, IconButton, FormControl, InputLabel, Select, Tooltip, Divider, TextField } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { getOfficerElectionSchedule, addOfficierElectionSchedule, updateOfficierElectionSchedule, deleteOfficerElectionSchedule } from '../../endpoints/members';
import { useApiGet, useApiSend } from '../../utils/httpClient';

const test_frequecy = [
    {
        id: '566caf72c6ca40af8bb89e0dfe9e800f',
        name: 'Weekly',
    },
    {
        id: 'd912dc2c45054401a38daac314e7ae1c',
        name: 'Bi-Weekly',
    },
    {
        id: '8ab9371acaec45d7a3d72154f401dbd7',
        name: 'Monthly',
    },
];

const days_of_week = [
    {
        id: '072cc0aabc844b7aad60c0e2437dda42',
        name: 'Sunday',
    },
    {
        id: 'ba44777b6720405392f2bfeae817d2cb',
        name: 'Monday',
    },
    {
        id: '8538aebaae4b4c9bbc23fdfb889ffc2a',
        name: 'Tuesday',
    },
    {
        id: '1802fa6bd2104dc3814ba4e9c659ba05',
        name: 'Wednesday',
    },
    {
        id: '08155763011e4dceaf3d65b9ef370af5',
        name: 'Thursday',
    },
    {
        id: '11d65c8ae2c74bd7abea06c5c3e9e625',
        name: 'Friday',
    },
    {
        id: 'f07ae738f0dc4b879dec61eb9d5bfe34',
        name: 'Saturday',
    },
];

const times_of_day = [
    {
        id: '12:00',
        name: '12:00',
    },
    {
        id: '12:30',
        name: '12:30',
    },
    {
        id: '1:00',
        name: '1:00',
    },
    {
        id: '1:30',
        name: '1:30',
    },
    {
        id: '2:00',
        name: '2:00',
    },
    {
        id: '2:30',
        name: '2:30',
    },
    {
        id: '3:00',
        name: '3:00',
    },
    {
        id: '3:30',
        name: '3:30',
    },
    {
        id: '4:00',
        name: '4:00',
    },
    {
        id: '4:30',
        name: '4:30',
    },
    {
        id: '5:00',
        name: '5:00',
    },
    {
        id: '5:30',
        name: '5:30',
    },
    {
        id: '6:00',
        name: '6:00',
    },
    {
        id: '6:30',
        name: '6:30',
    },
    {
        id: '7:00',
        name: '7:00',
    },
    {
        id: '7:30',
        name: '7:30',
    },
    {
        id: '8:00',
        name: '8:00',
    },
    {
        id: '8:30',
        name: '8:30',
    },
    {
        id: '9:00',
        name: '9:00',
    },
    {
        id: '9:30',
        name: '9:30',
    },
    {
        id: '10:00',
        name: '10:00',
    },
    {
        id: '10:30',
        name: '10:30',
    },
    {
        id: '11:00',
        name: '11:00',
    },
    {
        id: '11:30',
        name: '11:30',
    },
];

const months = [
    {
        id: '49f8bd874f1448eb8f4ec4153f5061c5',
        name: 'January',
    },
    {
        id: '1f9dc5e40df845e0b0a986abd7baefe0',
        name: 'February',
    },
    {
        id: 'bdb881b50e1a4393bdb863ed192f590d',
        name: 'March',
    },
    {
        id: '892e3a000fa94b9b8098513403a0a433',
        name: 'April',
    },
    {
        id: '2fb5b8dae5ef418c81b477d9fe122ee2',
        name: 'May',
    },
    {
        id: '8be492e9eb854922abc8609890535ab9',
        name: 'June',
    },
    {
        id: 'af8ba77828394d07a61e037ba88517a3',
        name: 'July',
    },
    {
        id: '7c1b6caedbaa4767bed246122fca25cb',
        name: 'August',
    },
    {
        id: '4236f50e18fc410e8924bf737d76eab8',
        name: 'September',
    },
    {
        id: 'c1a22ef2222842969eecda159d672b74',
        name: 'October',
    },
    {
        id: '10045ce54aaf484cb36e1810b0939b29',
        name: 'November',
    },
    {
        id: 'c9e23f562aa543e4bf2ae6a2e2801e9d',
        name: 'December',
    },
];

const monthlyTimes = [
    {
        id: '3d9dde1499da482cadda1a503ae484de',
        name: '1st',
        type: 'monthly'
    },
    {
        id: '0d032d935b6d4e9e96b9d1567a0f09a2',
        name: '2nd',
        type: 'monthly'
    },
    {
        id: '443ef4bc156443d68e7305e38b8da7ee',
        name: '3rd',
        type: 'monthly'
    },
    {
        id: '4e4de181e76544df83bea7e522ef1583',
        name: '4th',
        type: 'monthly'
    },
    {
        id: '7e661dd663974b9892e1a750164cae98',
        name: '1st and 3rd',
        type: 'biweekly'
    },
    {
        id: '9f53145b0b3044ea9487d9538ab38df2',
        name: '2nd and 4th',
        type: 'biweekly'
    },
]

const meetingFormats = [
    {
        id: 'befbf9d24f254882b678609c86880cf9',
        name: 'In-Person',
    },
    {
        id: 'd076e7db8e77473892263088e4f328cf',
        name: 'Virtual',
    },
    {
        id: '90aa631f3b714dd0b99c11593cf0af85',
        name: 'Hybrid',
    },
];


const ChapterMeetingInfo = ({id, handleNext, handleBack}) => {
    const [frequency, setFrequency] = useState(0);
    const [frequencyTiming, setFrequencyTiming] = useState(0);
    const [dayOfWeek, setDayOfWeek] = useState(0);
    const [timeOfDay, setTimeOfDay] = useState(0);
    const [midday, setMidday] = useState('pm');
    const [startMonth, setStartMonth] = useState(1);
    const [endMonth, setEndMonth] = useState(12);
    const [editId, setEditId] = useState(null);
    const [highlights, setHighlights] = useState('');

    const { data, isFetching, refetch } = useApiGet(
        ['election-meeting-info', id],
        getOfficerElectionSchedule,
        {
            enabled: id ? true : false,
        },
        { id }
    );

    const { mutate: createSchedule } = useApiSend(
        addOfficierElectionSchedule,
        (data) => {
          refetch();
          resetForm();
        },
        (error) => {
          console.log(error);
        }
      );
    
    const { mutate: updateSchedule } = useApiSend(
        updateOfficierElectionSchedule,
        (data) => {
          refetch();
        },
        (error) => {
          console.log(error);
        }
      );
    
    const { mutate: deleteSchedule } = useApiSend(
        deleteOfficerElectionSchedule,
        (data) => {
          refetch();
          resetForm();
        },
        (error) => {
          console.log(error);
        }
      );
    
    const resetForm = () => {
        setFrequency(0);
        setFrequencyTiming(0);
        setDayOfWeek(0);
        setTimeOfDay(0);
        setMidday('pm');
        setStartMonth(1);
        setEndMonth(12);
        setEditId(null);
    }

    useEffect(() => {
        if (data && data.chapter_highlights) {
            setHighlights(data.chapter_highlights);
        }
    }, [data]);

    const saveHighlights = () => {
        updateSchedule({id: id, chapter_highlights: highlights});
    }
    
    const checkDisabled = () => {
        if (frequency === 0 || dayOfWeek === 0 || timeOfDay === 0) {
            return true;
        }
        return false;
    }

    const addMeetingTime = () => {
        let data = {
            id: id,
            frequency_id: frequency,
            time_of_day: timeOfDay,
            midday: midday,
            frequency_timing_id: frequencyTiming || null,
            day_of_week_id: dayOfWeek,
            start_month_id: startMonth,
            end_month_id: endMonth,
        }
        if (editId) {
            data.schedule_id = editId
            createSchedule(data);
        } else {
            createSchedule(data);
        }
    }

    const deleteMeetingTime = (deleteId) => {
        deleteSchedule({id: id, schedule_id: deleteId});
    }

    const editMeetingTime = (meeting) => {
        setEditId(meeting.id);
        setFrequency(meeting.frequency_id);
        setDayOfWeek(meeting.day_of_week_id);
        setTimeOfDay(meeting.time_of_day);
        setMidday(meeting.midday);
        setFrequencyTiming(meeting.frequency_timing_id);
        setStartMonth(meeting.start_month_id);
        setEndMonth(meeting.end_month_id);
    }

    const frequencyTimingList = () => {
        if (frequency === '8ab9371acaec45d7a3d72154f401dbd7') {
            return monthlyTimes.filter((ft) => ft.type === 'monthly');
        } else if (frequency === 'd912dc2c45054401a38daac314e7ae1c') {
            return monthlyTimes.filter((ft) => ft.type === 'biweekly');
        } else {
            return [{id: 0, name: 'N/A'}];
        }
    }

    const handleSettingMeetingFormat = (e) => {
        let format = e.target.value;
        updateSchedule({id: id, local_chapter_type_id: format});
    }

    const isDisabled = () => {
        if (data && data.meeting_times.length > 0 && data.local_chapter_type_id) {
            return false;
        }
        return true;
    }
  
    return (
      <Grid container>
        <Grid item xs={12} textAlign={'center'} paddingTop={2}>
          <Typography variant="h4">Chapter Meeting Information</Typography>
        </Grid>
        {isFetching && 
          <Grid item xs={12} textAlign={'center'} paddingTop={2}>
            <Typography variant="h5">Loading...</Typography>
          </Grid>}
          {data && (
            <Grid container item xs={12}>
                <Grid item xs={12} padding>
                    <Typography variant="body2">
                    Please complete the Chapter Meeting Information form below. Sharing the
                    schedule and format for your meetings will help our members who may be considering a transfer to a new
                    chapter.
                    </Typography>
                </Grid>
                <Grid item xs={12} padding>
                    <Divider>
                        <Typography variant='overline'>Meeeting Format</Typography>
                    </Divider>
                </Grid>
              <Grid item xs={12} padding>
                <Typography variant="body2">
                    Please select the meeting format for your chapter meetings. Options include in-person, virtual, and hybrid
                    (a combination of both virtual and in-person meetings or meetings that offer both in-person and virtual formats).
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}/>
              <Grid item xs={12} sm={6} padding>
                <FormControl fullWidth>
                    <InputLabel id='meeting-format'>
                        Meeting Format
                    </InputLabel>
                    <Select
                        labelId='meeting-format'
                        id='meeting-format-select'
                        value={data.local_chapter_type_id}
                        label="Meeting Format"
                        size='small'
                        onChange={(e) => handleSettingMeetingFormat(e)}>
                        {meetingFormats.map((day) => (
                            <MenuItem key={day.id} value={day.id}>{day.name}</MenuItem>
                        ))}
                        </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}/>
              <Grid item xs={12} padding>
                <Divider>
                    <Typography variant='overline'>Chapter Highlights</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} padding>
                <Typography variant="body2">
                    Please enter a few sentences to highlight what makes your chapter special! Some examples include:
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft paddingRight>
                <Typography variant="caption" fontWeight={'bold'}>
                    Sample #1:
                </Typography>
                </Grid>
                <Grid item xs={12} paddingLeft paddingRight>
                <Typography variant="caption">
                    In addition to business meetings, our chapter enjoys social opportunities that include a wine club,
                    book club and Bunco group. We also try to connect with each other at two social events held each year.
                </Typography>
                </Grid>
                <Grid item xs={12} paddingLeft paddingRight>
                <Typography variant="caption" fontWeight={'bold'}>
                    Sample #2:
                </Typography>
                </Grid>
                <Grid item xs={12} paddingLeft paddingRight paddingBottom={3}>
                <Typography variant="caption">
                    We are a thriving chapter representing a multi-generational experience. We meet in a community center
                    that allows for easy parking and accessibility. Our social time includes informal snacks and wine—we
                    love to welcome new sisters!
                </Typography>
              </Grid>
              <Grid item xs={12} padding>
                <TextField
                    id='chapter-highlights'
                    label='Chapter Highlights'
                    multiline
                    rows={4}
                    fullWidth
                    value={highlights}
                    onChange={(e) => setHighlights(e.target.value)} />
              </Grid>
              {data.chapter_highlights !== highlights && (
              <Grid item xs={12} padding textAlign={'right'}>
                <Button
                    variant='contained'
                    color='green'
                    onClick={() => saveHighlights()}
                >
                    <Typography variant='body2'>Save</Typography>
                </Button>
            </Grid>)}
              <Grid item xs={12} padding>
                <Divider>
                    <Typography variant='overline'>Add Meeting Time</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} padding>
                <Typography variant="body2" paddingTop>
                    Please enter your chapter's meeting schedule. As an example, if your chapter meets on the
                    first Monday of every month (except December), and also on the fourth Friday in
                    July, this can be accounted for in two separate entries:
                </Typography>
                <Typography variant="body2" textAlign={'center'}>
                    Jan - November: Monthly on the 1st Monday @ 6:00 PM
                </Typography>
                <Typography variant="body2" textAlign={'center'}>
                    July - Monthly on the 4th Friday @ 6:00 PM
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} padding>
                <FormControl fullWidth>
                    <InputLabel id='start-month'>
                        Start Month
                    </InputLabel>
                    <Select
                        labelId='start-month'
                        id='start-month-select'
                        value={startMonth}
                        label="Start Month"
                        size='small'
                        onChange={(e) => setStartMonth(e.target.value)}>
                        {months.map((day) => (
                            <MenuItem key={day.id} value={day.id}>{day.name}</MenuItem>
                        ))}
                        </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding>
                <FormControl fullWidth>
                    <InputLabel id='end-month'>
                        End Month
                    </InputLabel>
                    <Select
                        labelId='end-month'
                        id='end-month-select'
                        value={endMonth}
                        label="End Month"
                        size='small'
                        onChange={(e) => setEndMonth(e.target.value)}>
                        {months.map((day) => (
                            <MenuItem key={day.id} value={day.id}>{day.name}</MenuItem>
                        ))}
                        </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} padding>
                <FormControl fullWidth>
                    <InputLabel id='day-of-week'>
                        Day of Week
                    </InputLabel>
                    <Select
                        labelId='day-of-week'
                        id='day-of-week-select'
                        value={dayOfWeek}
                        label="Day of Week"
                        size='small'
                        onChange={(e) => setDayOfWeek(e.target.value)}>
                        {days_of_week.map((day) => (
                            <MenuItem key={day.id} value={day.id}>{day.name}</MenuItem>
                        ))}
                        </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} padding>
                <FormControl fullWidth>
                    <InputLabel id='frequency'>
                        Frequency
                    </InputLabel>
                    <Select
                        labelId='frequency'
                        id='frequency-select'
                        value={frequency}
                        label="Frequency"
                        size='small'
                        onChange={(e) => setFrequency(e.target.value)}>
                        {test_frequecy.map((day) => (
                            <MenuItem key={day.id} value={day.id}>{day.name}</MenuItem>
                        ))}
                        </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} padding>
                <FormControl fullWidth>
                    <InputLabel id='frequency-timing'>
                        Frequency Timing
                    </InputLabel>
                    <Select
                        labelId='frequency-timing'
                        id='frequency-timing-select'
                        value={frequencyTiming}
                        label="Frequency Timing"
                        size='small'
                        onChange={(e) => setFrequencyTiming(e.target.value)}>
                        {frequencyTimingList().map((day) => (
                            <MenuItem key={day.id} value={day.id}>{day.name}</MenuItem>
                        ))}
                        </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} padding>
                <FormControl fullWidth>
                    <InputLabel id='time-of-day'>
                        Time of Day
                    </InputLabel>
                    <Select
                        labelId='time-of-day'
                        id='time-of-day-select'
                        value={timeOfDay}
                        label="Time of Day"
                        size='small'
                        onChange={(e) => setTimeOfDay(e.target.value)}>
                        {times_of_day.map((day) => (
                            <MenuItem key={day.id} value={day.id}>{day.name}</MenuItem>
                        ))}
                        </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} padding>
                <FormControl fullWidth>
                    <InputLabel id='midday'>
                        Midday
                    </InputLabel>
                    <Select
                        labelId='midday'
                        id='midday-select'
                        value={midday}
                        label="Midday"
                        size='small'
                        onChange={(e) => setMidday(e.target.value)}>
                            <MenuItem value='am'>AM</MenuItem>
                            <MenuItem value='pm'>PM</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} padding textAlign={'center'}>
                <Tooltip
                    title='Requires Day of Week, Frequency, Time of Day, and Midday'
                    placement='top'>
                    <span>
                    <Button
                        variant='text'
                        color='primary'
                        disabled={checkDisabled()}
                        onClick={() => addMeetingTime()}
                    >
                        {editId ? "Save" : "Add"}
                    </Button>
                    </span>
                </Tooltip>
                <Button
                    variant='text'
                    color='black'
                    onClick={() => {
                        setEditId(null);
                        setFrequency(0);
                        setDayOfWeek(0);
                        setTimeOfDay(0);
                        setFrequencyTiming(0);
                        setStartMonth(1);
                        setEndMonth(12);
                        setMidday('pm');
                    }}
                >
                    Clear
                </Button>
              </Grid>
              {data.meeting_times.length > 0 && (
              <Grid item xs={12}>
                    <Grid item xs={12} padding>
                        <Divider>
                            <Typography variant='overline'>Current Meeting Times</Typography>
                        </Divider>
                    </Grid>
                    {data.meeting_times.map((meeting) => (
                        <Grid item container padding={1}>
                            <Grid item xs={12} backgroundColor={editId === meeting.id ? 'lightgray' : 'white'}>
                              <Typography variant="body2" key={meeting.id}>
                                <IconButton size='small' p={1} color='blue' onClick={() => editMeetingTime(meeting)}>
                                    <Edit size='small'/>
                                </IconButton>
                                <IconButton size='small' p={1} color='error' onClick={() => deleteMeetingTime(meeting.id)}>
                                    <Delete />
                                </IconButton>
                                {meeting.start_month} - {meeting.end_month} - {meeting.frequency_timing} {meeting.day_of_week} {meeting.frequency} @ {meeting.time_of_day} {meeting.midday}
                              </Typography>
                            </Grid>
                        </Grid>
                    ))}
              </Grid>)}
              <Grid item xs={12} padding>
                <Divider/>
            </Grid>
              <Grid item container xs={12} padding paddingBottom={5}>
                <Grid item xs={12} sm={6} padding textAlign={'right'}>
                <Button
                    color='black'
                    variant='contained'
                    onClick={handleBack}
                    size='small'
                    padding={2}
                    >
                        <Typography fontWeight={'bold'} variant='body2'>Back</Typography>
                    </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} padding>
                    <Tooltip
                        title='Requires at least one meeting time and meeting format'
                        placement='top'>
                    <span>
                    <Button
                        color='primary'
                        variant='contained'
                        size='small'
                        onClick={handleNext}
                        disabled={isDisabled()}
                    >
                        <Typography fontWeight={'bold'} variant='body2'>Next: Officer Verification</Typography>
                    </Button>
                    </span>
                    </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
          )}
        </Grid>
    );
  };

export default ChapterMeetingInfo;