import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  Skeleton,
  Switch,
  Divider,
  InputAdornment,
  FormControlLabel,
  IconButton,
  ButtonGroup,
  Tooltip,
} from '@mui/material';
import { useApiGet, useApiSend } from '../../utils/httpClient';
import {
  getRosterDetail,
  updateRosterForm,
  resetRosterForm,
  updateFormApprovers,
} from '../../endpoints/annualReports';
import TabInfo from './TabInfo';
import EditIcon from '@mui/icons-material/Edit';
import FormStateSelector from '../../Components/FormStateSelector';
import UserSelectModal from '../../Components/UserSearchModal';
import { applications, formStates } from '../../utils/variables';
import { LoadingButton } from '@mui/lab';
import ConfirmationDialog from '../../Components/ConfirmationDialog';
import HistoryModal from '../../Components/HistoryModal';
import AlertItem from '../../Components/AlertItem';
import PageHeader from '../../Components/PageHeader';

function RosterDetail() {
  let { id } = useParams();
  const [hideContent, setHideContent] = useState(true);
  const [stateEdit, setStateEdit] = useState(false);
  const [assignedEdit, setAssignedEdit] = useState(false);
  const [openUserSelect, setOpenUserSelect] = useState(false);
  const [updateApprover, setUpdateApprover] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [resettingForm, setResettingForm] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [formSubmitOpen, setFormSubmitOpen] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);
  const [formSubmitMessage, setFormSubmitMessage] = useState('');

  const { data, isFetching, isSuccess, refetch } = useApiGet(
    ['roster', id],
    getRosterDetail,
    {
      enabled: true,
      retry: 1,
      refetchInterval: 15 * 60 * 1000, // The data will be refetched every 15 minutes
    },
    { id }
  );

  const { mutate } = useApiSend(
    updateRosterForm,
    (data) => {
      setAlertMessage('Roster successfully updated.');
      setAlertSeverity('success');
      setShowAlert(true);
      refetch();
    },
    (error) => {
      console.log(error);
    }
  );

  const { mutate: resetForm } = useApiSend(
    resetRosterForm,
    (data) => {
      refetch();
      setResettingForm(true);
    },
    (error) => {
      console.log(error);
    }
  );

  const { mutate: updateApproverRole } = useApiSend(
    updateFormApprovers,
    (data) => {
      setAlertMessage('Roster successfully updated.');
      setAlertSeverity('success');
      setShowAlert(true);
      setUpdateApprover(null);
      refetch();
    },
    (error) => {
      console.log(error);
    }
  );

  const handleSetAssigned = () => {
    setAssignedEdit(true);
    setUpdateApprover(null);
    setOpenUserSelect(true);
  };

  const handleSetApprover = (approver) => {
    setUpdateApprover(approver);
    setAssignedEdit(false);
    setOpenUserSelect(true);
  };

  const onStateUpdate = (state) => {
    setStateEdit(false);
    if (state === data.state_id || state === 0) {
      return;
    }
    mutate({
      id,
      status_id: data.status_id,
      state_id: state,
      assigned_to_id: data.assigned_to_id,
    });
  };

  const onUserUpdate = (assigned) => {
    setOpenUserSelect(false);
    if (assignedEdit) {
      if (assigned === data.assigned_to_id || assigned === 0) {
        return;
      }
      mutate({
        id,
        status_id: data.status_id,
        state_id: data.state_id,
        assigned_to_id: assigned,
      });
    } else if (updateApprover) {
      if (assigned === data.approver_id || assigned === 0) {
        return;
      }
      updateApproverRole({
        id,
        approver: updateApprover,
        form_type: 'roster',
        new_approver_id: assigned,
        update_all: false
      });
    }
  };

  const allowEdit = useCallback(() => {
    return data?.assigned_to_id === localStorage.getItem('user_id');
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      allowEdit();
      setResettingForm(false);
    }
  }, [data, allowEdit, isSuccess, setResettingForm]);

  const isARAdmin = () => {
    return localStorage
      .getItem('apps')
      .includes(applications.AnnualReportAdmin);
  };

  const handleConfirm = () => {
    setOpenConfirm(false);
    resetForm({ id });
  };

  const handleCancel = () => {
    setOpenConfirm(false);
  };

  const isAdmin = () => {
    return (
      localStorage.getItem('apps').includes(applications.SupportAdmin)
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {showAlert && (
          <AlertItem
            severity={alertSeverity}
            description={alertMessage}
            keepOpen={keepOpen}
            onClose={() => setShowAlert(false)}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <PageHeader
          trainingFilter={[formStates.LCTreas, formStates.LCPres].includes(data?.state_id) ? 'lc' : 'spd'}
          title={
            data?.spd_id
              ? 'Annual Report of State/Provincial/District Treasurer'
              : `Chapter ${data?.chapter_name}\\${data?.chapter_state} Roster and Dues Form`
          }
          subtitle={
            data?.spd_id
              ? `${data?.spd_name}`
              : isARAdmin()
                ? null
                : `Currently Assigned To: ${data?.assigned_to_name}`
          }
          rightDom={
            isARAdmin() ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={hideContent}
                    onChange={() => setHideContent(!hideContent)}
                  />
                }
                label={'Hide Info'}
              />
            ) : null
          }
        />
      </Grid>
      {isARAdmin() && !hideContent && (
        <Grid item xs={12}>
          <Grid item container>
            <Grid item xs={4} md={3} padding={2}>
              {isFetching ? (
                <Skeleton variant="text" />
              ) : (
                <TextField
                  id="chapter-name"
                  label="Chapter"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  value={data?.chapter_name ? data?.chapter_name : data?.spd_name}
                  variant="standard"
                />
              )}
            </Grid>
            <Grid item xs={4} md={3} padding={2}>
              {isFetching ? (
                <Skeleton variant="text" />
              ) : (
                <TextField
                  id="chapter-state"
                  label="Chapter State / Province / District"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data?.chapter_state ? data?.chapter_state : data?.spd_name}
                  variant="standard"
                />
              )}
            </Grid>
            <Grid item xs={4} md={3} padding={2}>
              <TextField
                id="current-status"
                label="Current Status"
                fullWidth
                value={data?.status}
                InputProps={{ readOnly: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={4} md={3} padding={2}>
              {stateEdit ? (
                <FormStateSelector
                  formState={data?.state_id}
                  handleStateChange={onStateUpdate}
                />
              ) : (
                <TextField
                  id="current-state"
                  label="Current Step"
                  fullWidth
                  value={data?.state}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={isAdmin() ? false : true}
                          color={'blue'}
                          onClick={() => setStateEdit(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              )}
            </Grid>
            <Grid item xs={4} md={3} padding={2}>
              <TextField
                id="assigned-to"
                label="Assigned To"
                fullWidth
                value={data?.assigned_to_name}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color={'blue'} onClick={handleSetAssigned}>
                        <EditIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={4} md={3} padding={2}>
              {isFetching ? (
                <Skeleton variant="text" />
              ) : (
                <TextField
                  id="disbanded"
                  label="Disbanded"
                  fullWidth
                  defaultValue={data?.is_disbanded ? 'Yes' : 'No'}
                  variant="standard"
                />
              )}
            </Grid>
            <Grid item xs={4} md={6} padding={2} textAlign={'right'}>
              <ButtonGroup>
                <Tooltip
                  title="View actions taken on the form."
                  placement="top-end"
                >
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ fontWeight: 'bold' }}
                    onClick={() => setShowHistory(true)}
                  >
                    Form History
                  </LoadingButton>
                </Tooltip>
                <Tooltip
                  title="Reset the entire form to its original state (cannot be undone)."
                  placement="top-end"
                >
                  <span>
                    <LoadingButton
                      variant="contained"
                      color="black"
                      size="small"
                      disabled={isAdmin() ? false : true}
                      sx={{ fontWeight: 'bold' }}
                      onClick={() => setOpenConfirm(true)}
                    >
                      Reset Form
                    </LoadingButton>
                  </span>
                </Tooltip>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} padding={2}>
              <Divider>
                <Typography
                  variant="overline"
                  component="div"
                  textAlign={'center'}
                  color="primary"
                >
                  Approvers
                </Typography>
              </Divider>
            </Grid>

            {data?.spd_id === null ? (
              <Grid item container>
                <Grid item xs={4} md={3} padding={2}>
                  <TextField
                    id="lc_treasurer"
                    label="LC Treasurer"
                    fullWidth
                    value={data?.approvers.lc_treasurer}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color={'blue'}
                            onClick={() => handleSetApprover('lc_treasurer')}
                            disabled={isARAdmin() ? false : true}
                          >
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={3} padding={2}>
                  <TextField
                    id="lc_president"
                    label="LC President"
                    fullWidth
                    value={data?.approvers.lc_president}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color={'blue'}
                            onClick={() => handleSetApprover('lc_president')}
                            disabled={isARAdmin() ? false : true}
                          >
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={3} padding={2}>
                  <TextField
                    id="spd_treasurer"
                    label="SPD Treasurer"
                    fullWidth
                    value={data?.approvers.spd_treasurer}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color={'blue'}
                            onClick={() => handleSetApprover('spd_treasurer')}
                            disabled={true}
                          >
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={3} padding={2}>
                  <TextField
                    id="spd_secretary"
                    label="SPD Secretary"
                    fullWidth
                    value={data?.approvers.spd_secretary}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color={'blue'}
                            onClick={() => handleSetApprover('spd_secretary')}
                            disabled={true}
                          >
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item container>
                <Grid item xs={4} md={3} padding={2}>
                  <TextField
                    id="spd_treasurer"
                    label="SPD Treasurer"
                    fullWidth
                    value={data?.approvers.spd_treasurer}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color={'blue'}
                            onClick={() => handleSetApprover('spd_treasurer')}
                            disabled={true}
                          >
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={3} padding={2}>
                  <TextField
                    id="spd_president"
                    label="SPD President"
                    fullWidth
                    value={data?.approvers.spd_president}
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color={'blue'}
                            onClick={() => handleSetApprover('spd_president')}
                            disabled={true}
                          >
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <TabInfo
        setFormSubmitMessage={setFormSubmitMessage}
        setFormSubmitOpen={setFormSubmitOpen}
        setParentKeepOpen={setKeepOpen}
        parentAlertMessage={setAlertMessage}
        parentAlertSeverity={setAlertSeverity}
        parentShowAlert={setShowAlert}
        currentState={data?.current_state}
        formStatusId={data?.status_id}
        formStateId={data?.state_id}
        assigned={allowEdit()}
        refetchParent={refetch}
        resettingForm={resettingForm}
        spdApprovable={data?.approvable}
        irsForm={data?.irs_form}
        spdForm={data?.spd_id ? true : false}
        isDisbanded={data?.is_disbanded}
      />
    <ConfirmationDialog
      open={openConfirm}
      handleOk={handleConfirm}
      handleCancel={handleCancel}
      title={'Reset Form'}
      message={
        'Are you sure you want to reset this form? This action cannot be undone.'
      }
      confirmText={'Reset Form'}
      cancelText={'Cancel'}
    />
    <HistoryModal
      modalOpen={showHistory}
      setModalOpen={() => setShowHistory(false)}
      rosterId={id}
    />
    <UserSelectModal
      modalOpen={openUserSelect}
      setModalOpen={setOpenUserSelect}
      setUser={onUserUpdate}
    />
    <ConfirmationDialog
      open={formSubmitOpen}
      handleOk={() => setFormSubmitOpen(false)}
      title={
        formSubmitMessage.includes('rejected')
          ? 'Form Rejected'
          : 'Form Submitted'
      }
      message={formSubmitMessage}
      confirmText={'Close'}
    />
    </Grid>
  );
}

export default RosterDetail;
