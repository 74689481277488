import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Box, Link, Divider } from '@mui/material';
import { useApiGet } from '../../utils/httpClient';
import { getTrainingDetail } from '../../endpoints/training';
import ReactPlayer from 'react-player';
import PageHeader from '../../Components/PageHeader';
import trainingLogo from '../../assets/trainingcopy.png';

const lc_trainings = [
  '33f533f6c174451682ab2f5c2dd64a67',
  '3d51d9556c884ecf9de88e5a326a9706',
  '4a8985296ae342ec952f84e80d9ae510',
  '909e809c43174cbca0ba29d4ebf27535',
  '91fc0f2aad0d4d1bbe1c2e56a78e706c',
  'd15f19fca5cf4152aee84a0caba0db7c',
  'e7f5e49b41e04409b79ce99100c11854',
  'eadcf9aa77e64dcdbdec80e36728f953',
  'efedb5c760e2465aa7d570c0415daa9a'
]

export default function TrainingDetail() {

  let { id } = useParams();
  const { data } = useApiGet(
    ['training-detail', id],
    getTrainingDetail,
    { enabled: id ? true : false },
    { id }
  );

  const openLink = (url) => {
    window.open(url, '_blank');
  }

  return data ? (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader title={data.name} />
      </Grid>
      <Grid container item xs={12} padding>
        <Grid item xs={12}>
          <ReactPlayer
            className="react-player"
            width="100%"
            height='70vh'
            url={data.video_url}
            playsinline={true}
            controls={true}
          />
        </Grid>
        {lc_trainings.includes(id) ? (
          <Grid item xs={12} padding={2}>
            <Typography variant="body1">
              <Link
                onClick={() => openLink('https://storage.googleapis.com/peo-portal-static/AnnualReportMembershipRosterLCTreasurer_Final.pdf')}
                color="secondary"
                underline="always"
              >
                Local Chapter Roster - Treasurer
              </Link>
            </Typography>
            <Typography variant="body1">
              <Link
                onClick={() => openLink('https://storage.googleapis.com/peo-portal-static/AnnualReportMembershipRosterLCPresident_Final.pdf')}
                color="secondary"
                underline="always"
              >
                Local Chapter Roster - President
              </Link>
            </Typography>
            <Typography variant="body1">
              <Link
                onClick={() => openLink('https://storage.googleapis.com/peo-portal-static/AnnualReportIRS-LCOnlineLCTreasurer_Final.pdf')}
                color="secondary"
                underline="always"
              >
                Local Chapter IRS-LC - Treasurer
              </Link>
            </Typography>
            <Typography variant="body1">
              <Link
                onClick={() => openLink('https://storage.googleapis.com/peo-portal-static/AnnualReportIRS-LCOnlineLCPresidents_Final.pdf')}
                color="secondary"
                underline="always"
              >
                Local Chapter IRS-LC - President
              </Link>
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} padding={2}>
            <Typography variant="body1">
              <Link
                onClick={() => openLink('https://storage.googleapis.com/peo-portal-static/AnnualReportInstructionsSPDOfficer_Final.pdf')}
                color="secondary"
                underline="always"
              >
                SPD Roster
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} padding={3}>
        <Divider />
      </Grid>
      <Grid item container xs={12} padding>
        <Grid item xs={12} padding>
          <Typography variant="h6" component={'div'} fontWeight={'bold'}>
            Related Trainings
          </Typography>
        </Grid>
        {data.related_trainings.map((training) => (
          <Grid
            item
            key={training.id}
            xs={4}
            sm={3}
            md={2}
            textAlign={'center'}
            paddingLeft={2}
            paddingRight={2}
          >
            <Box
              onClick={() =>
                (window.location.href = `/training/${training.id}`)
              }
              component="img"
              src={training.icon_image_url || trainingLogo}
              sx={{ width: '100%', height: 'auto' }}
            />
            <Typography variant="body1" component={'div'}>
              {training.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  ) : null;
}
