import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import {
  Grid,
  Box,
  ButtonGroup,
  Tooltip,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateDialog from './DateDialog';
import {
  updateRosterMember,
  resetRosterMember,
  addLostContact
} from '../../endpoints/annualReports';
import { useApiSend } from '../../utils/httpClient';
import ApprovalButton from './ApprovalButton';
import { formStatuses, formStates, applications } from '../../utils/variables';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import RosterMemberModal from './RosterMemberDetail';
import HistoryModal from '../../Components/HistoryModal';
import UserSelectModal from '../../Components/UserSearchModal';
import HistoryIcon from '@mui/icons-material/History';
import ConfirmationDialog from '../../Components/ConfirmationDialog';

const columns = [
  {
    width: 315,
    label: 'Action',
    dataKey: 'action',
    skip: true,
  },
  {
    width: 120,
    label: 'Name',
    dataKey: 'full_name',
  },
  {
    width: 120,
    label: 'Member ID',
    dataKey: 'imis_id',
  },
  {
    width: 50,
    label: null,
    dataKey: 'info',
    skip: true,
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
    />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.dataKey === 'action' ? 'left' : 'right'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function InactiveMembers({
  rowData,
  refetch,
  editable,
  refetchParent,
  currentStatusId,
  assigned,
  formStateId,
  alertOpen,
  alertMessage,
  alertSeverity,
}) {
  const [date, setDate] = useState(null);
  const [dateRequested, setDateRequested] = useState(false);
  const [action, setAction] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dateRestricted, setDateRestricted] = useState(true);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [verified, setVerified] = useState(false);
  const [addLCMember, setAddLCMember] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  let { id } = useParams();

  const { mutate } = useApiSend(
    updateRosterMember,
    (data) => {
      setAction(null);
      setMemberId(null);
      setDate(null);
      refetch();
      setInProgress(false);
    },
    (error) => {
      console.log(error);
      setInProgress(false);
    }
  );

  const { mutate: resetMember } = useApiSend(
    resetRosterMember,
    (data) => {
      refetch();
      refetchParent();
      setInProgress(false);
    },
    (error) => {
      setInProgress(false);
    }
  );

  const { mutate: addLCMemberToRoster } = useApiSend(
    addLostContact,
    (data) => {
      alertOpen()
      alertMessage('Member was successfully added.')
      alertSeverity('success')
      refetch();
      setInProgress(false);
    },
    (error) => {
      console.log(error)
      setInProgress(false);
      alertOpen()
      alertMessage(`Error adding member. ${error.error}`)
      alertSeverity('error')
    }
  );

  const buildRequest = (dateValue = null) => {
    let request = {
      rosterId: id,
      memberId: memberId,
    };
    switch (action) {
      case 'reinstate':
        request['reinstated'] = true;
        request['reinstated_date'] = dateValue.format('YYYY-MM-DD');
        break;
      case 'transfer':
        request['transfer_out'] = true;
        request['transfer_date'] = dateValue.format('YYYY-MM-DD');
        break;
      case 'passed':
        request['passed'] = true;
        request['passed_date'] = dateValue.format('YYYY-MM-DD');
        break;
      default:
        break;
    }
    return request;
  };

  const handleClose = (dateValue) => {
    setDateRequested(false);
    if (dateValue) {
      // perform action
      mutate(buildRequest(dateValue));
    } else {
      setInProgress(false);
    }
  };

  const handleButtonClick = (action, member) => {
    setMemberId(member.id);
    setAction(action);
    setInProgress(true);
    let actionsRequiringDate = [
      'reinstate',
      'lost-contact',
      'transfer',
      'passed',
    ];
    if (actionsRequiringDate.includes(action)) {
      if (
        action === 'passed' &&
        !['AA', 'AR', null].includes(member.imis_status)
      ) {
        setDateRestricted(false);
        setDateRequested(true);
      } else {
        setDateRestricted(true);
        setDateRequested(true);
      }
    } else {
      mutate({
        rosterId: id,
        memberId: member.id,
        is_active: true,
      });
    }
  };

  const handleOpenModal = (id) => {
    setMemberId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setMemberId(null);
    setShowModal(false);
  };

  const handleConfirm = () => {
    setOpenConfirm(false);
    setInProgress(true);
    resetMember({
      rosterId: id,
      memberId: memberId,
    });
  };

  const handleCancel = () => {
    setOpenConfirm(false);
  };

  const handleResetMember = (member) => {
    setMemberId(member.id);
    setOpenConfirm(true);
  };

  const handleHistoryOpen = (id) => {
    setMemberId(id);
    setShowHistoryModal(true);
  };

  const handleCloseHistoryModal = () => {
    setMemberId(null);
    setShowHistoryModal(false);
  };

  const handleLCMemberSelect = (userId) => {
    setOpenUserModal(false);
    console.log('I Got Called')
    if (addLCMember) {
      addLCMemberToRoster({
        id,
        user_id: userId,
      });
    }
  };

  const handleLCAdd = () => {
    setAddLCMember(true);
    setOpenUserModal(true);
  };

  const isAdmin = () => {
    return (
      localStorage.getItem('apps').includes(applications.SupportAdmin)
    );
  };

  const rowContent = (_index, row) => {
    return (
      <React.Fragment>
        <TableCell key={'action'} align={'left'}>
          <ButtonGroup size="small" variant="outlined">
            {['AA', 'AR', null].includes(row.imis_status) ? (
              <Tooltip
                title="Places member in Active status."
                placement="top-end"
              >
                <LoadingButton
                  key="active"
                  color="green"
                  loading={row.id === memberId ? inProgress : false}
                  disabled={!editable}
                  sx={{ fontWeight: 'bold' }}
                  onClick={() => handleButtonClick('reactivate', row)}
                >
                  Active
                </LoadingButton>
              </Tooltip>
            ) : (
              <Tooltip
                title="Moves member to active list by reinstating them (date required)."
                placement="top-end"
              >
                <LoadingButton
                  key="reinstate"
                  color="green"
                  loading={row.id === memberId ? inProgress : false}
                  disabled={!editable}
                  sx={{ fontWeight: 'bold' }}
                  onClick={() => handleButtonClick('reinstate', row)}
                >
                  Reinstate
                </LoadingButton>
              </Tooltip>
            )}
            <Tooltip
              title="Member transferred to another chapter."
              placement="top-end"
            >
              <LoadingButton
                key="transfer"
                color="blue"
                loading={row.id === memberId ? inProgress : false}
                disabled={!editable}
                sx={{ fontWeight: 'bold' }}
                onClick={() => handleButtonClick('transfer', row)}
              >
                Transferred
              </LoadingButton>
            </Tooltip>
            <Tooltip title="Member passed away." placement="top-end">
              <LoadingButton
                key="passed"
                color="black"
                loading={row.id === memberId ? inProgress : false}
                disabled={!editable}
                sx={{ fontWeight: 'bold' }}
                onClick={() => handleButtonClick('passed', row)}
              >
                Passed
              </LoadingButton>
            </Tooltip>
            <Tooltip
              title="Resets member to original status (before any updates)."
              placement="top-end"
            >
              <LoadingButton
                key="reset"
                color="warning"
                loading={row.id === memberId ? inProgress : false}
                sx={{ fontWeight: 'bold' }}
                disabled={
                  !editable ||
                  ![formStates.LCTreas].includes(
                    formStateId
                  )
                }
                onClick={() => handleResetMember(row)}
              >
                Reset
              </LoadingButton>
            </Tooltip>
          </ButtonGroup>
          <DateDialog
            open={dateRequested}
            dateRestricted={dateRestricted}
            onClose={handleClose}
            dateValue={date}
            action={action}
          />
        </TableCell>
        {columns
          .filter((item) => !item.skip)
          .map((column) => (
            <TableCell key={column.dataKey} align={'right'}>
              {row[column.dataKey]}
            </TableCell>
          ))}
        <TableCell key={'info'} align={'right'}>
          <Tooltip title="View Member Details" placement="top">
            <IconButton onClick={() => handleOpenModal(row.id)}>
              <InfoOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Member History" placement="top">
            <IconButton onClick={() => handleHistoryOpen(row.id)} size="small">
              <HistoryIcon color="primary" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </React.Fragment>
    );
  };

  return (
    <Grid container>
      {!editable && (
        <Grid item xs={12} padding textAlign={'center'}>
          <Typography variant="body2" sx={{ pb: 1 }}>
            This tab currently cannot be edited. This is due to: Required work
            needing to be completed on a tab to the left or current assignee /
            step does not allow.
          </Typography>
        </Grid>
      )}
      {isAdmin() && (
        <Grid container item xs={12} padding>
          <Grid item xs={12} padding>
            <LoadingButton
              color="primary"
              variant="contained"
              size="small"
              onClick={handleLCAdd}
            >
              <Typography fontWeight={'bold'} variant="body2">
                Add LC Member
              </Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      )}
      {currentStatusId === formStatuses.InactiveMembers && editable && (
        <Grid container item xs={12} md={5} padding>
          <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={verified}
                    onChange={(e) => setVerified(e.target.checked)}
                    size={'medium'}
                  />
                }
                label="All members below have been verified."
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} padding>
            <ApprovalButton
              refetchParent={refetchParent}
              alertMessage={(message) => alertMessage(message)}
              alertOpen={alertOpen}
              alertSeverity={(severity) => alertSeverity(severity)}
              id={id}
              disabled={!verified}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} padding>
        <Box sx={{ height: 500 }}>
          <TableVirtuoso
            data={rowData}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
        </Box>
      </Grid>
      <UserSelectModal
        modalOpen={openUserModal}
        setModalOpen={setOpenUserModal}
        setUser={handleLCMemberSelect}
        activeMembers={false}
      />
      <RosterMemberModal
        modalOpen={showModal}
        setModalOpen={handleCloseModal}
        userId={memberId}
      />
      <HistoryModal
        modalOpen={showHistoryModal}
        setModalOpen={handleCloseHistoryModal}
        memberId={memberId}
      />
      <ConfirmationDialog
        open={openConfirm}
        handleOk={handleConfirm}
        handleCancel={handleCancel}
        title={'Reset Member'}
        message={
          'This will send the member back to their original state.  If the member is new or transferred in, they will be removed from the list. This update could require the other tabs to be reverified. Are you sure you want to reset this member? This action cannot be undone.'
        }
        confirmText={'Reset Member'}
        cancelText={'Cancel'}
      />
    </Grid>
  );
}
