import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import Dashboard from './Pages/Dashboard/Dashboard';
import Documents from './Pages/Documents/Documents';
import NotFound from './Pages/NotFound';
import Login from './Pages/Login/Login';
import Roster from './Pages/Roster/Roster';
import RosterDetail from './Pages/Roster/RosterDetail';
import Irs from './Pages/Irs/Irs';
import IrsDetail from './Pages/Irs/IrsDetail';
import Reports from './Pages/Reports/Reports';
import Training from './Pages/Training/Training';
import TrainingCreate from './Pages/Training/TrainingCreate';
import SiteAppBar from './SiteAppBar';
import ReleaseNotes from './Pages/ReleaseNotes';
import { Grid } from '@mui/material';
import Footer from './Footer';
import TrainingDetail from './Pages/Training/TrainingDetail';
import Donations from './Pages/Donations/public/Donations';
import UserARList from './Pages/Users/UserARList';
import UserARDetail from './Pages/Users/UserARDetail';
import UserList from './Pages/Users/UserList';
import CreateUser from './Pages/Users/CreateUser';
import { applications } from './utils/variables';
import LCChapterList from './Pages/Chapters/LCChapterList';
import SPDChapterList from './Pages/Chapters/SPDChapterList';
import GroupsList from './Pages/Chapters/GroupsList';
import ReciprocityList from './Pages/Chapters/ReciprocityList';
import BnBList from './Pages/BedAndBreakfast/BnBList';
import DonationsList from './Pages/Donations/internal/DonationsList';
import TLCList from './Pages/TLC/TLCList';
import ELFLanding from './Pages/Projects/ELF';
import StarLandingPage from './Pages/Projects/Star';
import PCELandingPage from './Pages/Projects/PCE';
import PSALandingPage from './Pages/Projects/PSA';
import IPSLandingPage from './Pages/Projects/IPS';
import FoundationLandingPage from './Pages/Projects/Foundation';
import MyChapter from './Pages/MyChapter/MyChapter';
import ShopLandingPage from './Pages/Shop/Shop';
import DonationDetail from './Pages/Donations/internal/DonationDetail';
import UserDetail from './Pages/Users/UserDetail';
import OfficierElection from './Pages/Chapters/OffElection';

const PrivateRoutes = () => {
  let auth = localStorage.getItem('user_id');
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

const UserManagement = () => {
  let apps = localStorage.getItem('apps');
  return apps.includes(applications.UserManagement) ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

const IsAdmin = () => {
  let user = localStorage.getItem('user_id');
  return user && user === '66d358dd04d146bf86aeff36a7ccd679' ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

const loggedIn = localStorage.getItem('user_id');

const Router = () => {
  return (
    <BrowserRouter>
      <Grid sx={{ position: 'relative', minHeight: '100vh' }}>
        <SiteAppBar />
        <Grid item xs={12} paddingBottom={18}>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/roster" element={<Roster />} />
              <Route path="/roster/:id" element={<RosterDetail />} />
              <Route path="/irs" element={<Irs />} />
              <Route path="/irs/:id" element={<IrsDetail />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/training" element={<Training />} />
              <Route path="/training/:id" element={<TrainingDetail />} />
              <Route path="/training/create" element={<TrainingCreate />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="/release-notes" element={<ReleaseNotes />} />
              <Route element={<UserManagement />}>
                <Route path="/users" element={<UserARList />} />
                <Route path="/users/create" element={<CreateUser />} />
              </Route>
              <Route path="/users/:id" element={<UserARDetail />} />
              <Route path="/local-chapters/elections/:id" element={<OfficierElection />} />
              <Route element={<IsAdmin />}>
                <Route path="/local-chapters" element={<LCChapterList />} />
                <Route path="/spd-chapters" element={<SPDChapterList />} />
                <Route path="/groups" element={<GroupsList />} />
                <Route path="/reciprocity" element={<ReciprocityList />} />
                <Route path="/bnb" element={<BnBList />} />
                <Route path="/internal/donations" element={<DonationsList />} />
                <Route
                  path="/internal/donations/:id"
                  element={<DonationDetail />}
                />
                <Route path="/tlc" element={<TLCList />} />
                <Route path="/user" element={<UserList />} />
                <Route path="/user/:id" element={<UserDetail />} />
                <Route path="/projects/elf" element={<ELFLanding />} />
                <Route path="/projects/star" element={<StarLandingPage />} />
                <Route path="/projects/pce" element={<PCELandingPage />} />
                <Route path="/projects/psa" element={<PSALandingPage />} />
                <Route path="/projects/ips" element={<IPSLandingPage />} />
                <Route path="/foundation" element={<FoundationLandingPage />} />
                <Route path="/my-chapter" element={<MyChapter />} />
                <Route path="/shop" element={<ShopLandingPage />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/donations" element={<Donations />} />
          </Routes>
        </Grid>
        {loggedIn && (
        <Grid item xs={12}>
          <Footer />
        </Grid>)}
      </Grid>
    </BrowserRouter>
  );
};

export default Router;
