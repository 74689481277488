import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { Grid, Typography, Card, CardContent, Divider } from '@mui/material';
import dayjs from 'dayjs';

function DashboardCard({ title, value, color, description }) {
  return (
    <Card height={100}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant="overline">{title}</Typography>
          </Grid>
          <Grid item xs={12} textAlign={'center'}>
            {color === 'primary' ? (
              <Typography variant="h3" component="h2" color={color}>
                {value}
              </Typography>
            ) : (
              <Typography variant="h3" component="h2" sx={{ color: { color } }}>
                {value}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant="overline">{description}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function ExecDashboard({
  data,
  label,
  spdSelected,
  regionSelected,
}) {
  // data is an object with the following structure:
  // {
  //     roster: [
  //         {date: "YYYY-MM-DD", spd: 123, region: 0, completed_forms: 0, lc_forms: 0, spd_forms: 0, total_forms: 0},
  //     ],
  //     irs: [
  //         {date: "YYYY-MM-DD", spd: 123, region: 0, completed_forms: 0, lc_forms: 0, spd_forms: 0, total_forms: 0},
  //     ],
  // }

  const [localChapterTotal, setLocalChapterTotal] = useState(0);
  const [spdChapterTotal, setSpdChapterTotal] = useState(0);
  const [completedTotal, setCompletedTotal] = useState(0);

  const [irsLcTotal, setIrsLcTotal] = useState(0);
  const [irsSpdTotal, setIrsSpdTotal] = useState(0);
  const [irsCompletedTotal, setIrsCompletedTotal] = useState(0);

  useEffect(() => {
    // Need to get last day then loop through the data summimg the totals

    let localChapter = 0;
    let spdChapter = 0;
    let completed = 0;

    let irsLc = 0;
    let irsSpd = 0;
    let irsCompleted = 0;

    if (data.roster && data.roster.length > 0) {
      // sort data by date so the newest is first
      let sortedData = data.roster.sort((a, b) => {
        return dayjs(a.date).diff(dayjs(b.date));
      });

      let currentDay = dayjs(sortedData[sortedData.length - 1].date);
      sortedData.forEach((item) => {
        if (dayjs(item.date).isSame(currentDay, 'day')) {
          localChapter += item.lc_forms;
          spdChapter += item.spd_forms;
          completed += item.completed_forms;
        }
      });

      setLocalChapterTotal(localChapter);
      setSpdChapterTotal(spdChapter);
      setCompletedTotal(completed);
    }

    if (data.irs && data.irs.length > 0) {
      // sort data by date so the newest is first
      let sortedData = data.irs.sort((a, b) => {
        return dayjs(a.date).diff(dayjs(b.date));
      });

      let currentDay = dayjs(sortedData[sortedData.length - 1].date);
      sortedData.forEach((item) => {
        if (dayjs(item.date).isSame(currentDay, 'day')) {
          irsLc += item.lc_forms;
          irsSpd += item.spd_forms;
          irsCompleted += item.completed_forms;
        }
      });

      setIrsLcTotal(irsLc);
      setIrsSpdTotal(irsSpd);
      setIrsCompletedTotal(irsCompleted);
    }
  }, [data]);

  const createTimeSeriesKeys = (data, form) => {
    let keys = [];
    data[form].forEach((item) => {
      let k = dayjs(item.date).format('MMM DD');
      if (!keys.includes(k)) {
        keys.push(k);
      }
    });
    return keys;
  };

  const createTimeSeriesData = (data, form) => {
    let tempData = {};

    data[form].forEach((item) => {
      if (regionSelected && regionSelected !== 'all') {
        if (Object.keys(tempData).includes(item.spd)) {
          tempData[item.spd].push(
            Math.round((item.completed_forms / item.total_forms) * 100)
          );
        } else {
          tempData[item.spd] = [
            Math.round((item.completed_forms / item.total_forms) * 100),
          ];
        }
      } else {
        if (Object.keys(tempData).includes(item.region)) {
          tempData[item.region].push(
            Math.round((item.completed_forms / item.total_forms) * 100)
          );
        } else {
          tempData[item.region] = [
            Math.round((item.completed_forms / item.total_forms) * 100),
          ];
        }
      }
    });
    return Object.keys(tempData).map((key) => {
      return {
        data: tempData[key],
        label: key,
      };
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {label}
        </Typography>
      </Grid>
      {spdSelected ? (
        <Grid item xs={12}>
          <BarChart
            series={[
              {
                data: [
                  { id: 0, value: 10, label: 'series A' },
                  { id: 1, value: 15, label: 'series B' },
                  { id: 2, value: 20, label: 'series C' },
                ],
              },
            ]}
            width={400}
            height={200}
          />
        </Grid>
      ) : (
        <Grid item container>
          <Grid item xs={12} padding textAlign={'center'}>
            <Typography variant="h5" color="primary">
              Roster Dues
            </Typography>
          </Grid>
          <Grid item xs={12} padding paddingRight={5} paddingLeft={5}>
            <LineChart
              height={300}
              series={createTimeSeriesData(data, 'roster')}
              xAxis={[
                {
                  scaleType: 'point',
                  data: createTimeSeriesKeys(data, 'roster'),
                },
              ]}
              yAxis={[{ label: 'Completion Percent', scaleType: 'linear' }]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} padding>
            <DashboardCard
              title="Roster Dues"
              value={localChapterTotal}
              color="primary"
              description={'Local Chapter'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} padding>
            <DashboardCard
              title="Roster Dues"
              value={spdChapterTotal}
              color="primary"
              description={'SPD Chapter'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} padding>
            <DashboardCard
              title="Roster Dues"
              value={completedTotal}
              color="primary"
              description={'Completed'}
            />
          </Grid>
          <Grid item xs={12} padding paddingRight={5} paddingLeft={5}>
            <Divider />
          </Grid>
          <Grid item xs={12} padding textAlign={'center'}>
            <Typography variant="h5" color="primary">
              IRS-LC
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} padding>
            <DashboardCard
              title="IRS-LC"
              value={irsLcTotal}
              color="primary"
              description={'Local Chapter'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} padding>
            <DashboardCard
              title="IRS-LC"
              value={irsSpdTotal}
              color="primary"
              description={'SPD Chapter'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} padding>
            <DashboardCard
              title="IRS-LC"
              value={irsCompletedTotal}
              color="primary"
              description={'Completed'}
            />
          </Grid>
          <Grid item xs={12} padding paddingRight={5} paddingLeft={5}>
            <LineChart
              height={300}
              series={createTimeSeriesData(data, 'irs')}
              xAxis={[
                { scaleType: 'point', data: createTimeSeriesKeys(data, 'irs') },
              ]}
              yAxis={[{ label: 'Completion Percent', scaleType: 'linear' }]}
            />
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} padding textAlign={'center'}>
            <Typography variant="h5" color="primary">
              Local Chapter Election of Officers
            </Typography>
          </Grid>
          <Grid item sm={12} md={2} />
          <Grid item xs={12} sm={6} md={4} padding>
            <DashboardCard
              title="Election of Officers"
              value={data?.election.pending}
              color="primary"
              description={'Pending'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} padding>
            <DashboardCard
              title="Election of Officers"
              value={data?.election.completed}
              color="primary"
              description={'Completed'}
            />
          </Grid>
          <Grid item xs={12} paddingBottom={5}/>
        </Grid>
      )}
    </Grid>
  );
}
