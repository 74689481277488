import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

// http://localhost:8080
// https://api.peo-portal.com
// https://test-api.peo-portal.com

const client = axios.create({
  baseURL: 'https://api.peo-portal.com',
  responseType: 'json',
  timeout: 20000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

export const formClient = axios.create({
  baseURL: 'https://api.peo-portal.com',
  responseType: 'json',
  timeout: 10000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

export const documentClient = axios.create({
  baseURL: 'https://api.peo-portal.com',
  responseType: 'blob',
  timeout: 10000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

export const request = async (options) => {
  const onSuccess = (response) => {
    console.debug('Request Successful!', response);
    return response.data;
  };
  const onError = (error) => {
    console.error('Request Failed:', error.toJSON());
    if (error.response?.status === 401) {
      window.location.href = '/login';
      let email = localStorage.getItem('email');
      localStorage.clear();
      if (email) {
        localStorage.setItem('email', email);
      }
    }
    return Promise.reject(error.response?.data || error.message);
  };

  return client({ ...options })
    .then(onSuccess)
    .catch(onError);
};

export const useApiGet = (key, fn, options, stringParams = null) =>
  useQuery({
    queryKey: key,
    queryFn: stringParams ? () => fn(stringParams) : () => fn(),
    ...options,
  });

export const useApiSend = (fn, success, error, invalidateKey, options) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fn,
    onSuccess: (data) => {
      invalidateKey &&
        invalidateKey.forEach((key) => {
          queryClient.invalidateQueries(key);
        });
      success && success(data);
    },
    onError: error,
    retry: 0,
    ...options,
  });
};
