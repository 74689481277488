import React, {useEffect, useState} from 'react';
import { Grid, Typography, Button, IconButton } from '@mui/material';
import { getOfficerElectionInstructions } from '../../endpoints/members';
import { useApiGet, useApiSend } from '../../utils/httpClient';
import { applications } from '../../utils/variables';
import { Edit } from '@mui/icons-material';
import UserSelectModal from '../../Components/UserSearchModal';
import { updateElectionOfficerAssignee } from '../../endpoints/members';


const ElectionInstructions = ({id, handleNext, formStep}) => {

  const [openUserSelect, setOpenUserSelect] = useState(false);
  const [isLoading, setIsFetching] = useState(false);

  const { data, isFetching, refetch } = useApiGet(
    ['election-instructions', id],
    getOfficerElectionInstructions,
    {
      enabled: id ? true : false,
    },
    { id }
  );

  const { mutate } = useApiSend(
    updateElectionOfficerAssignee,
    (data) => {
      setIsFetching(false);
      refetch();
    },
    (error) => {
      console.log(error);
    }
  );
  
  useEffect(() => {
    if (formStep === 0) {
      refetch();
    }
  }, [formStep, refetch]);

  const isAdmin = () => {
    return (
      localStorage.getItem('apps').includes(applications.SupportAdmin)
    );
  };

  const onUserUpdate = (user) => {
    setOpenUserSelect(false);
    setIsFetching(true);
    mutate({id: id, new_user_id: user});
  }

  return (
    <Grid container>
      <Grid item xs={12} textAlign={'center'} paddingTop={2}>
        <Typography variant="h4">Local Chapter Annual Election of Officers</Typography>
        <Typography variant="h5">Instructions</Typography>
      </Grid>
      <Grid item xs={12}>
      {isFetching && isLoading && <Typography variant="h5">Loading...</Typography>}
        {data && !isFetching && !isLoading && (
          <Grid item container>
            <Grid item xs={12} textAlign={'center'} padding={2}>
                <Typography variant="body2" fontWeight={'bold'}>Chapter: {data.name}</Typography>
                <Typography variant="body2" fontWeight={'bold'}>
                  Assignee: {data.assigned_to_name}
                  {isAdmin() && (
                  <IconButton
                    size="small"
                    color='blue'
                    onClick={() => setOpenUserSelect(true)}
                  >
                    <Edit />
                  </IconButton>)}
                </Typography>
                <Typography variant="body2" fontWeight={'bold'}>Status: {data.status_name}</Typography>
                
            </Grid>
            <Grid item xs={12}>
                <Typography variant='body2'>
                  The election of officers for the local chapter is an important event. It not only identifies the
                  chapter’s leadership, but it also enables the chapter to report essential information needed by
                  the P.E.O. International membership department. This information includes verifying and updating
                  chapter meeting details and verifying the tenure of your chapter’s officers.
                </Typography>
                <Typography variant='body2' paddingTop={2}>
                  The Election of Officers Form must be completed once your chapter officers have been installed.
                  After the form is submitted, it can be updated as many times as needed after the initial
                  submission. The form will be locked when alternative methods for updating the information
                  become available throughout the year.
                </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} textAlign={'center'} padding={2}>
        <Button variant='contained' onClick={handleNext}>
            <Typography fontWeight={'bold'}>
                Next: Chapter Schedule
            </Typography>
        </Button>
      </Grid>
      <UserSelectModal
        modalOpen={openUserSelect}
        setModalOpen={setOpenUserSelect}
        setUser={onUserUpdate}
      />
    </Grid>
  );
};

export default ElectionInstructions;