import React, { useEffect, useState } from 'react';
import { useApiSend } from '../../utils/httpClient';
import { login } from '../../endpoints/login';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import logo from './../../assets/PEO_InformalLogo_Color.png';
import { jwtDecode } from 'jwt-decode';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const { mutate } = useApiSend(
    login,
    (data) => {
      setIsLoading(false);
      if (rememberMe) {
        localStorage.setItem('email', email);
      }
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('refresh', data.refresh_token);
      const decoded = jwtDecode(data.access_token);
      localStorage.setItem('user_id', decoded.sub);
      localStorage.setItem('first_name', decoded.first_name);
      localStorage.setItem('last_name', decoded.last_name);
      localStorage.setItem('apps', decoded.applications);
      window.location.href = '/';
    },
    (error) => {
      setError(error.message || 'An error occurred. Please try again later.');
      setIsLoading(false);
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    mutate({ email, password });
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
    if (localStorage.getItem('email')) {
      localStorage.removeItem('email');
    }
  };
  let emailLS = localStorage.getItem('email');

  useEffect(() => {
    if (localStorage.getItem('email')) {
      setEmail(localStorage.getItem('email'));
      setRememberMe(true);
    }
  }, [emailLS]);

  const handleLinkPress = (e) => {
    e.preventDefault();
    window.open('https://peointernational.formstack.com/forms/annual_report_request_form', '_blank');
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    window.open('https://imis.peointernational.org/iMISLive/MemberSite/MemberSite/Sign_In.aspx', '_blank');
  };

  return (
    <Grid
      container
      direction={'column'}
      justifyContent="center"
      alignItems="center"
      sx={{ verticalAlign: 'middle' }}
    >
      <Box
        component="img"
        sx={{
          height: 'auto',
          width: 'auto',
          maxHeight: { xs: 150, md: 250 },
          maxWidth: { xs: 350, md: 250 },
          mt: 10,
        }}
        alt="Logo."
        src={logo}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ 
          mt: 1,
          pt: 5,
          height: '20vh',
          width: '50vw',
        }}
      >
        <TextField
          variant="standard"
          required
          fullWidth
          id="email"
          label="Email Address"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="standard"
          required
          sx={{ mt: 2 }}
          fullWidth
          autoComplete="current-password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          id="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              color="primary"
              onChange={handleRememberMe}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Remember me"
        />
        <LoadingButton
          type="submit"
          fullWidth
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          sx={{ mt: 3 }}
        >
          Sign In
        </LoadingButton>
        <Grid item xs textAlign={'center'}>
          {error && (
            <Typography
              variant="overline"
              color="error"
              align="center"
              fontWeight={'bold'}
            >
              {error}
            </Typography>
          )}
        </Grid>
        <Grid container paddingTop={1} justifyContent={'space-between'}>
        <Grid item>
            <Link onClick={handleResetPassword}>
              <Typography variant="body2" color="primary">
                Forgot Password
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link onClick={handleLinkPress}>
              <Typography variant="body2" color="primary">
                Contact Us
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Login;
