import { request } from '../utils/httpClient';

export const getStates = () =>
  request({
    url: '/roster_form/states',
    method: 'GET',
  });

export const updateRosterForm = (data) => {
  const formId = data.id;
  delete data.id;

  return request({
    url: `/roster/${formId}`,
    method: 'PATCH',
    data: data,
  });
};

export const getStatuses = (data) => {
  let url = '/roster_form/status';
  if (data.stateId) {
    url += `?state=${data.stateId}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};

export const getRosterForms = (data) => {
  // build url based on form data
  let url = `/roster_dues_list?page_size=${data.rowsPerPage}&page=${data.page}`;
  if (data.stateProvince) {
    url += `&state=${data.stateProvince}`;
  }
  if (data.formStatus !== 'all') {
    url += `&status=${data.formStatus}`;
  }
  if (data.disbanded !== 'all') {
    url += `&disbanded=${data.disbanded}`;
  }
  if (data.search) {
    url += `&search=${data.search}`;
  }
  if (data.orderBy) {
    url += `&order_by=${data.orderBy}`;
  }
  if (data.order) {
    url += `&order=${data.order}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};

export const getRosterDetail = (data) => {
  return request({
    url: `/roster/${data.id}`,
    method: 'GET',
  });
};

export const getRosterMembers = (data) => {
  let url = `/roster/${data.id}/members`;

  if (data.status) {
    url += `?status=${data.status}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};

export const updateRosterMember = (data) => {
  let rosterId = data.rosterId;
  let memberId = data.memberId;
  delete data.rosterId;
  delete data.memberId;
  return request({
    url: `/roster/${rosterId}/members/${memberId}`,
    method: 'PATCH',
    data: data,
  });
};

export const approveRosterForm = (data) => {
  return request({
    url: `/roster/${data.id}/approve`,
    method: 'PATCH',
  });
};

export const rejectRosterForm = (data) => {
  return request({
    url: `/roster/${data.id}/reject`,
    method: 'PATCH',
  });
};

export const getMember = (data) => {
  return request({
    url: `/roster_member/${data.userId}`,
    method: 'GET',
  });
};

export const searchMembers = (data) => {
  return request({
    url: `/roster_member/search?search=${data.searchText}`,
    method: 'GET',
  });
};

export const transferMember = (data) => {
  const memberId = data.memberId;
  delete data.memberId;

  return request({
    url: `/roster_member/${memberId}/transfer`,
    method: 'POST',
    data: data,
  });
};

export const createMember = (data) => {
  return request({
    url: `/roster_member`,
    method: 'POST',
    data: data,
  });
};

export const getDashboardData = (data) => {
  let url = '/dashboard';
  if (data.spd) {
    url += `?spd=${data.spd}`;
  }

  if (data.region && data.region !== 'all' && !data.spd) {
    url += `?region=${data.region}`;
  }

  return request({
    url: url,
    method: 'GET',
  });
};

export const getStateProvinces = () => {
  return request({
    url: '/roster/stateProvince',
    method: 'GET',
  });
};

export const getRosterDues = (data) => {
  return request({
    url: `/roster/${data.id}/due_rates`,
    method: 'GET',
  });
};

export const approveDuesForm = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/roster/${formId}/form/approve`,
    method: 'POST',
    data: data,
  });
};

export const rejectDuesForm = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/roster/${formId}/form/reject`,
    method: 'POST',
    data: data,
  });
};

export const getIrsStates = () => {
  return request({
    url: '/irs/state',
    method: 'GET',
  });
};

export const getIrsStatuses = (data) => {
  return request({
    url: '/irs/status',
    method: 'GET',
  });
};

export const updateIrsForm = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/irs/${formId}`,
    method: 'PATCH',
    data: data,
  });
};

export const getIRSList = (data) => {
  let url = `/irs_list?page_size=${data.rowsPerPage}&page=${data.page}`;
  if (data.stateProvince) {
    url += `&state=${data.stateProvince}`;
  }
  if (data.formStatus !== 'all') {
    url += `&status=${data.formStatus}`;
  }
  if (data.search) {
    url += `&search=${data.search}`;
  }
  if (data.orderBy) {
    url += `&order_by=${data.orderBy}`;
  }
  if (data.order) {
    url += `&order=${data.order}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};

export const getIRSDetail = (data) => {
  return request({
    url: `/irs/${data.id}`,
    method: 'GET',
  });
};

export const getIRSQuestions = (data) => {
  return request({
    url: `/irs/${data.id}/questions`,
    method: 'GET',
  });
};

export const irsFileUpload = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/irs/${formId}/upload`,
    method: 'POST',
    data: data,
  });
};

export const irsSubmitAnswers = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/irs/${formId}/answers/submit`,
    method: 'POST',
    data: data,
  });
};

export const irsApprove = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/irs/${formId}/form/approve`,
    method: 'POST',
    data: data,
  });
};

export const irsReject = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/irs/${formId}/form/reject`,
    method: 'POST',
    data: data,
  });
};

export const getChapters = (data) => {
  let url = '/chapters';
  if (data.spd_id) {
    url += `?spd_id=${data.spd_id}`;
  }
  return request({
    url: url,
    method: 'GET',
  });
};

export const getSpds = () => {
  return request({
    url: '/spds',
    method: 'GET',
  });
};

export const stateProvinces = () => {
  return request({
    url: '/member/state_province',
    method: 'GET',
  });
};

export const resetRosterForm = (data) => {
  return request({
    url: `/roster/${data.id}/reset`,
    method: 'PATCH',
  });
};

export const resetRosterMember = (data) => {
  let rosterId = data.rosterId;
  let memberId = data.memberId;
  return request({
    url: `/roster/${rosterId}/members/${memberId}/reset`,
    method: 'PATCH',
  });
};

export const getRosterMemberHistory = (data) => {
  return request({
    url: `/roster_member/${data.id}/history`,
    method: 'GET',
  });
};

export const getRosterHistory = (data) => {
  return request({
    url: `/roster/${data.id}/history`,
    method: 'GET',
  });
};

export const getIrsHistory = (data) => {
  return request({
    url: `/irs/${data.id}/history`,
    method: 'GET',
  });
};

export const getRosterDocument = (data) => {
  return request({
    url: `/roster/${data.id}/document`,
    method: 'POST',
  });
};

export const getIrsDocument = (data) => {
  return request({
    url: `/irs/${data.id}/document`,
    method: 'POST',
  });
};

export const getRegions = () => {
  return request({
    url: '/spd_regions',
    method: 'GET',
  });
};

export const updateFormApprovers = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/form/${formId}/approvers`,
    method: 'PATCH',
    data: data,
  });
}

export const addLostContact = (data) => {
  let formId = data.id;
  delete data.id;
  return request({
    url: `/roster/${formId}/lost-contact`,
    method: 'POST',
    data: data,
  });
}
