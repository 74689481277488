import { request } from '../utils/httpClient';

export const searchUsers = (data) => {
  let url = '/users/search';
  if (data.searchText) {
    url += `?query=${data.searchText}`;
  }

  if (data.chapter) {
    url += `&chapter=${data.chapter}`;
  }

  if (data.membersOnly) {
    url += `&members_only=${data.membersOnly}`;
  }

  return request({
    url: url,
    method: 'GET',
  });
};

export const getUsers = (data) => {
  let url = '/users-list?';

  if (data.page) {
    url += `page=${data.page}`;
  }

  if (data.rowsPerPage) {
    url += `&page_size=${data.rowsPerPage}`;
  }

  if (data.search) {
    url += `&search=${data.search}`;
  }

  return request({
    url: url,
    method: 'GET',
  });
};

export const getUser = (data) => {
  return request({
    url: `/users/${data.id}`,
    method: 'GET',
  });
};

export const updateUser = (data) => {
  let userId = data.id;
  delete data.id;
  return request({
    url: `/users/${userId}`,
    method: 'PATCH',
    data: data,
  });
};

export const createUser = (data) => {
  return request({
    url: '/users',
    method: 'POST',
    data: data,
  });
};

export const createUserMember = (data) => {
  let memberId = data.memberId;
  delete data.memberId;

  return request({
    url: `/users/member/${memberId}`,
    method: 'POST',
    data: data,
  });
};

export const getRoles = () => {
  return request({
    url: 'users/roles',
    method: 'GET',
  });
};

export const getApplications = () => {
  return request({
    url: 'users/applications',
    method: 'GET',
  });
};

export const getReports = () => {
  return request({
    url: 'users/reports',
    method: 'GET',
  });
};
